import React from 'react'
import { useEffect } from 'react'

export default function Coframe() {
    const coframeProjectId = '6740d7e8e8d40ca9093bb215'
    useEffect(() => { window.CFQ?.push({ emit: 'pageHydrated' }) }, [])

    return (
        <>
            <script dangerouslySetInnerHTML={{
                __html: `
                // Coframe antiflicker: hide body until coframe:show event or 2s timeout
                const style = document.createElement('style');
                style.innerHTML = 'body { opacity: 0 !important; }';
                const cfhide = () => document.head.appendChild(style);
                const cfshow = () => style.remove();
                cfhide(); setTimeout(cfshow, 2000);
                document.addEventListener('coframe:show', cfshow);
                
                // Coframe queue setup:
                window.CFQ = window.CFQ || [];
                window.CFQ.push({config: {
                    projectId: '${coframeProjectId}',
                    waitForHydration: true,
                }});`
            }} />
            <script strategy="beforeInteractive" src={`https://cdn.coframe.com/cf.min.js?project_id=${coframeProjectId}`} />
        </>
    )
}