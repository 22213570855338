/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ index, follow, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: `Q5JmPKqCzmv-x2pbLgdHZohtB20dUk7Yy29pCF-HFsE`,
        },
        {
          name: `robots`,
          content: `${index ? `index` : `noindex`}, ${
            follow ? `follow` : `nofollow`
          }`,
        },
      ].concat(meta)}
      script={[
    //     {
    //       type: `text/javascript`,
    //       id: `test-coframe-snippet`,
    //       innerHTML:`
    //           // Coframe antiflicker: hide body until coframe:show event or 2s timeout
    // const style = document.createElement('style');
    // style.innerHTML = 'body { opacity: 0 !important; }';
    // const cfhide = () => document.head.appendChild(style);
    // const cfshow = () => style.remove();
    // cfhide(); setTimeout(cfshow, 2000);
    // document.addEventListener('coframe:show', cfshow);

    // // Coframe queue setup:
    // window.CFQ = window.CFQ || [];
    // window.CFQ.push({config: {
    //     projectId: "6740d7e8e8d40ca9093bb215",
    // }});
    //         `
    //     },
    //     {
    //       type: `text/javascript`,
    //       id: `test-coframe-script`,
    //       async: true,
    //       src: `https://cdn.coframe.com/cf.min.js?project_id=6740d7e8e8d40ca9093bb215`,
    //     },
    {
      type: `text/javascript`,
      defer: `false`,
      innerHTML: `
      console.log('page loaded in:' + (window.performance.timing.navigationStart - window.performance.timing.domContentLoadedEventEnd) + 'ms');
      let startMeasureTime = Date.now();
      let smallTest = setInterval(() => {
        if(document.querySelector('.spz-class') && document.querySelectorAll('.spz-class').length === 6){
          clearInterval(smallTest)
          let endTime = Date.now();
          let timeSpan = endTime - startMeasureTime;
          console.log('Small Experiment loaded in:'+timeSpan+'ms');
        }
      }, 1);
      let bigTest = setInterval(() => {
        if(document.querySelector('.frequenylyaskedquestions-wrapper.v2')){
          clearInterval(bigTest)
          let endTime = Date.now();
          let timeSpan = endTime - startMeasureTime;
          console.log('Big Experiment loaded in:'+timeSpan+'ms');
        }
      }, 1);
      `
    }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
